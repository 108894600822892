<template>
  <v-btn class="mx-2" @click="print" fab small title="Imprimir Romaneio">
    <v-icon>
      mdi-printer
    </v-icon>
  </v-btn>
</template>

<script>

import http from '@/modules/http';
import moment from 'moment';

  export default {
    props: {
      delivery: {
        type: Object,
        default: null
      },
    },
    methods: {
      cutName(name, ref_num = 20) {
        if (name.length > ref_num) {
          return name.substring(0, ref_num - 3) + "...";
        } else {
          return name;
        }
      },
      async print() {
        let delivery = null;
        let qty = 0;
        let total = 0;

        delivery = await http.get('api/deliveries/'+this.delivery.id)
        
          let mywindow = window.open(
            "",
            "PRINT",
            "height=" + screen.height + ",width=" + screen.width
          );

          mywindow.document.write("<html><head><title>Imprimir Romaneio</title>");
          mywindow.document.write('</head><body style="font-family:sans-serif;" >');
          mywindow.document.write(
            '<div style="display: flex; justify-content: center;">',
              '<img style="width: 100%;position: absolute; opacity: .1;" src="http://transporteslatina.com.br/logo.png" />',
              '<h2 style="align-self: center;">ROMANEIO DE ENTREGAS | nº '+ delivery.data.id +'</h2>',
            '</div>',
            '<div>',
              '<h3 style="font-size: 14px">Motorista: '+ delivery.data.drivers.name +' <span style="margin-left: 20px">Data: '+ moment(delivery.data.date).format('DD/MM/YYYY') +'</span> <span style="margin-left: 20px">KM Inicial: ____________</span> <span style="margin-left: 20px">KM Final: ____________</span></h3>',
            '</div>',
            '<table cellspacing="0" border="1">',
            '<tr style="font-size: 12px">',
              '<th>CTe</th>',
              '<th>NF</th>',
              '<th>Transp.</th>',
              '<th>Remetente</th>',
              '<th>Destinatário</th>',
              '<th>Cidade</th>',
              '<th>Frete</th>',
            '</tr>',
            );
          
          delivery.data.delivery_ctes.forEach(e => {
            
            qty ++;
            total += parseFloat(e.ctes.freight_value);

            mywindow.document.write(
              '<tr style="font-size: 12px">',
                '<td>'+ e.ctes.number +'</td>',
                '<td>'+ this.cutName(e.ctes.nf, 12) +'</td>',
                '<td>'+ e.ctes.partners.name +'</td>',
                '<td>'+ this.cutName(e.ctes.senders.name) +'</td>',
                '<td>'+ this.cutName(e.ctes.recipients.name) +'</td>',
                '<td>'+ this.cutName(e.ctes.alternative_city_id ? e.ctes.alternative_cities.name: e.ctes.recipients.cities.name) +'</td>',
                '<td style="text-align: center;">'+ this.$functions.moneyFormat(e.ctes.freight_value) +'</td>',
              '</tr>'
            );
          });

          mywindow.document.write(
            '<tr style="font-size: 12px">',
              '<td><strong>Qtd Docs:</strong></td>',
              '<td><strong>'+ qty +'</strong></td>',
              '<td></td>',
              '<td></td>',
              '<td></td>',
              '<td></td>',
              '<td><strong>'+ this.$functions.moneyFormat(total.toFixed(2)) +'</strong></td>',
            '</tr>'
          );

          mywindow.document.write('</table>',
            '</br>',
            '<p>', 
              'Assinatura do motorista: _____________________________ || ',
              'Assinatura do conferente: _____________________________',
            '</p>',
            '<style>',
              'td {',
                'padding: 0 10px',
              '}',
            '</style>',
          );
          mywindow.document.write("</body></html>");
        
      }
    },
  }
</script>

<style scoped>

</style>