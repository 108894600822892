<template>
  <div>
    <v-form ref="formCreateDelivery" @submit.prevent="save()">
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            :rules="requiredRules"
            label="Data"
            type="date"
            v-model="delivery.date"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            label="Motorista"
            :items="drivers"
            item-text="name"
            item-value="id"
            :loading="loading"
            :rules="requiredRules"
            v-model="delivery.driver_id"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn class="mt-2" color="secondary" type="submit" :loading="saving">
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import http from "@/modules/http";
export default {
  props: {
    propDate: {
      type: String,
      default: new Date().toISOString().slice(0,10),
    },
  },
  data() {
    return {
      delivery: {
        date: this.propDate,
        driver_id: "",
      },
      drivers: [],
      loading: false,
      saving: false,
      requiredRules: [(v) => !!v || "Este campo é obrigatório"],
    };
  },
  mounted() {
    this.getDrivers();
  },
  methods: {
    clearForm() {
      this.delivery = {
        date: "",
        driver_id: "",
      };
      this.$refs.formCreateDelivery.reset();
    },
    save() {
      if (this.$refs.formCreateDelivery.validate()) {
        this.saving = true;
        http
          .post("api/deliveries", this.delivery)
          .then((res) => {
            this.saving = false;
            this.$side({
              type: "success",
              msg: "Salvo com sucesso",
              duration: 4000,
            });
            this.clearForm();
            this.$emit('deliverySaved', true);
          })
          .catch((err) => {
            this.saving = false;
            this.$side({
              type: "error",
              msg: "Erro ao salvar",
              duration: 4000,
            });
            let errors = this.$functions.validationToArray(err);

            if (errors) {
              errors.forEach((e) => {
                this.$side({
                  type: "error",
                  msg: e[1][0],
                  duration: 5000,
                });
              });
            }
          });
      }
    },
    getDrivers() {
      this.loading = true;
      http
        .get("api/drivers")
        .then((res) => {
          this.loading = false;
          this.drivers = res.data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
</style>