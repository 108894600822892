<template>
  <div>
    <h1>{{ addDelivery ? "Adicionar Romaneio" : "Romaneios" }}</h1>
    <v-row>
      <v-col cols="12">
        <div class="d-flex">
          <v-btn
            v-if="!addDelivery"
            @click="addDelivery = true"
            color="success"
            title="Adicionar Romaneio"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-btn
            v-if="addDelivery"
            @click="addDelivery = false"
            color="error"
            title="Voltar"
            class="mx-2"
          >
            <v-icon>mdi-keyboard-return</v-icon>
          </v-btn>

          <v-btn
            @click="$router.push('/ctes')"
            color="warning"
            title="CTes"
            class="mx-2"
          >
            CTes
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-switch
      v-model="showNf"
      flat
      :label="`Mostrar NF: ${showNf ? 'Sim' : 'Não'}`"
      style="width: 200px"
    ></v-switch>

    <create-delivery :propDate="date" @deliverySaved="deliverySaved()" v-if="addDelivery" />

    <div v-if="!addDelivery">
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field :disabled="mounting" type="date" label="Data" v-model="date"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            :items="partners"
            item-text="name"
            item-value="id"
            label="Parceira"
            :loading="loading"
            v-model="partner"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn
            @click="mountBlocks()"
            title="Atualizar"
            color="secondary"
            class="ma-2"
            :loading="loading"
            :disabled="mounting"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            @click="save()"
            :loading="saving"
            title="Salvar"
            color="success"
            class="ma-2"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="d-flex flex-wrap">
        <span v-for="p in partners" class="subtitle" :style="`background: ${colors[p.id - 1]};`" :key="p.name">
          {{ p.name }}
        </span>
      </div>
      <v-row>
        <v-col cols="12">
          <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
          <div class="ctes-container" v-if="!loading">
            <v-row>
              <v-col cols="12" md="6">
                <div
                  v-for="(delivery, i) in deliveries"
                  :key="i+'delivery'"
                >
                  <v-card
                    class="card-ctes"
                    v-if="i != deliveries.length - 1"
                  >
                    <v-card-title>
                      {{ deliveryDriver[i].driver.name }}
                      <v-icon
                        v-if="deliveryDriver.length - 1 !== i"
                        title="Marcar todos como entregues"
                        class="mx-2"
                        color="success"
                        @click="setAllAsDelivered(i)"
                      >
                        mdi-check
                      </v-icon>
                      <v-icon
                        v-if="deliveryDriver.length - 1 !== i"
                        title="Excluir romaneio (Em breve...)"
                        class="mx-2"
                        color="error"
                        @click="deleteDelivery(deliveryDriver[i])"
                      >
                        {{ !deleting ? `mdi-delete` : `mdi-clock-outline` }}
                      </v-icon>
                      <print-delivery :delivery="deliveryDriver[i]" />
                    </v-card-title>
                    <v-card-text>
                      <!-- <v-text-field
                        :label="deliveryDriver.length - 1 !== i ? 'CTe para incluir' : 'CTe para remover de um romaneio'"
                        append-icon="mdi-check"
                      ></v-text-field> -->
                      <draggable
                        class="draggable-container"
                        :list="deliveries[i]"
                        group="deliveries"
                        @start="drag = true"
                        @end="drag = false"
                      >
                        <div :style="`background: ${colors[d.partner_id - 1]};`" class="ctes-list" v-for="(d, idx) in deliveries[i]" :key="idx+'colors'">
                          {{ !showNf ? d.number : d.nf }}
                        </div>
                      </draggable>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <v-card
                  class="card-ctes"
                  v-if="deliveries.length > 0"
                >
                  <v-card-title>
                    {{ deliveryDriver[deliveries.length - 1].driver.name }}
                    <v-icon
                      v-if="deliveryDriver.length - 1 !== deliveries.length - 1"
                      title="Marcar todos como entregues"
                      class="mx-2"
                      color="success"
                      @click="setAllAsDelivered(deliveries.length - 1)"
                    >
                      mdi-check
                    </v-icon>
                    <v-icon
                      v-if="deliveryDriver.length - 1 !== deliveries.length - 1"
                      title="Excluir romaneio (Em breve...)"
                      class="mx-2"
                      color="error"
                      @click="deleteDelivery(deliveryDriver[deliveries.length - 1])"
                    >
                      {{ !deleting ? `mdi-delete` : `mdi-clock-outline` }}
                    </v-icon>
                  </v-card-title>
                  <v-card-text>
                    <!-- <v-text-field
                      :label="deliveryDriver.length - 1 !== i ? 'CTe para incluir' : 'CTe para remover de um romaneio'"
                      append-icon="mdi-check"
                    ></v-text-field> -->
                    <draggable
                      class="draggable-container"
                      :list="deliveries[deliveries.length - 1]"
                      group="deliveries"
                      @start="drag = true"
                      @end="drag = false"
                    >
                      <div :style="`background: ${colors[d.partner_id - 1]}; ${d.partner_id == partner || partner == null ? 'opacity: 1;' : 'opacity: 0.1;'}`" class="ctes-list" v-for="d in deliveries[deliveries.length - 1]" :key="d.id+'deliveries'">
                        {{ !showNf ? d.number : d.nf }}
                      </div>
                    </draggable>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col class="d-flex" cols="12" md="6"> </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

import http from "@/modules/http";
import draggable from "vuedraggable";
import createDelivery from "../components/Create";
import PrintDelivery from '../components/PrintDelivery';

export default {
  components: {
    createDelivery,
    draggable,
    PrintDelivery
  },
  data() {
    return {
      mounting: false,
      colors: ['#de726a', '#6c89d4', '#7ccf84', '#c5c977', '#b39de3', '#8a7c76'],
      deleting: false,
      addDelivery: false,
      loading: false,
      ctes: [],
      date: new Date().toISOString().slice(0, 10),
      deliveries: [],
      deliveryDriver: [],
      showNf: false,
      saving: false,
      partners: [],
      partner: null,
    };
  },
  methods: {
    getPartners() {
      http
        .get('api/partners')
        .then(res => {
          this.partners = res.data;
          this.partners.push({id: null, name: 'Tudo'});
        })
        .catch(err => {
          console.log(err);
          this.$side({
            type    : 'error',
            msg     : 'Erro ao carregar os parceiros',
            duration: 4000
          });
        });
    },
    deleteDelivery(delivery) {
      if(this.deliveries[delivery.index].length > 0) {
        this.$side({
          type    : 'error',
          msg     : 'Não é possível excluir um romaneio com CTes!',
          duration: 6000
        });
      } else {
        this.deleting = true;
        http
          .delete('api/deliveries/'+delivery.id)
          .then(() => {
            this.deleting = false;
            this.$side({
              type    : 'success',
              msg     : 'Excluído com sucesso',
              duration: 4000
            });
            this.mountBlocks();
          })
          .catch(err => {
            this.deleting = false;
            if(err.response.status && err.response.status === 400) {
              this.$side({
                type    : 'error',
                msg     : err.response.data.msg,
                duration: 6000
              })
            }
            this.$side({
              type    : 'error',
              msg     : 'Erro ao excluir',
              duration: 4000
            });
          })
      }
    },
    setAllAsDelivered(i) {
      this.$confirm({
        type: "success",
        title: "Marcar como entregue",
        content:
          "Tem certeza que deseja todos os ctes deste romaneio como entregues?",
        confirmText: "Sim",
        cancelText: "Não",
        confirm: () => {
          //console.log(this.deliveries[i]);
          http
            .post("api/set-ctes-delivered", {
              ctes: this.deliveries[i],
              date: this.date,
            })
            .then((res) => {
              console.log(res);
              this.$side({
                type: "success",
                msg: "Salvo com sucesso",
                duration: 4000,
              });
              this.mountBlocks();
            })
            .catch((err) => {
              console.log(err);
            });
          this.$close(); //If you don't want to close the dialog, you can not use it.
        },
        cancel: () => {
          this.$close(); //If you don't want to close the dialog, you can not use it.
        },
      });
    },
    save() {
      this.saving = true;
      http
        .post("api/handle-deliveries", {
          ctes: this.deliveries,
          deliveries: this.deliveryDriver,
        })
        .then((res) => {
          this.saving = false;
          this.$side({
            type: "success",
            msg: "Salvo com sucesso",
            duration: 4000,
          });
          console.log(res);
        })
        .catch((err) => {
          this.saving = false;
          this.$side({
            type: "Error",
            msg: "Erro ao salvar",
            duration: 4000,
          });
          console.log(err);
        });
    },
    deliverySaved() {
      this.addDelivery = false;
      this.mountBlocks();
    },
    setDeliveries(data) {
      let index = 0;

      data.forEach((element) => {
        this.deliveries.push([]);
        this.deliveryDriver.push({
          id: element.id,
          index: index,
          driver: element.drivers,
        });
        index++;
      });
      this.deliveries.push([]);
      this.deliveryDriver.push({
        id: null,
        index: index,
        driver: { id: null, name: "Sem Romaneio" },
      });

      this.getCtes();
    },
    setCtes(data) {
      this.deliveryDriver.forEach((dd) => {
        data.forEach((el) => {

          if (
            el.deliveries.length < 1 &&
            dd.index == this.deliveries.length - 1
          ) {
            this.deliveries[this.deliveries.length - 1].push(el);
          } else {
            if (
              el.deliveries.length > 0 &&
              el.deliveries[0].delivery_id === dd.id
            ) {
              this.deliveries[dd.index].push(el);
            }
          }

        });
      });
      this.mounting = false;
    },

    getDeliveries() {
      http
        .get("api/deliveries?date=" + this.date)
        .then((res) => {
          this.setDeliveries(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCtes() {
      this.loading = true;
      http
        .get("api/ctes?condition=create-delivery")
        .then((res) => {
          this.loading = false;
          this.setCtes(res.data);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    mountBlocks() {
      this.mounting = true;
      this.deliveries = [];
      this.deliveryDriver = [];
      this.getDeliveries();
      //this.getCtes();
    },
  },
  mounted() {
    this.getPartners();
    this.mountBlocks();
  },
};
</script>

<style scoped>
.ctes-list {
  margin: 10px;
  text-align: center;
  padding: 5px 20px;
  cursor: move;
  min-width: 100px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px #353535;
  color: black;
}
.subtitle {
  margin: 10px;
  text-align: center;
  padding: 5px 20px;
  min-width: 100px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px #353535;
  color: black;
}
.draggable-container {
  background-color: #85ccc7;
  padding: 10px;
  border-radius: 10px;
  min-width: 80px;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.card-ctes {
  margin: 10px 10px;
  width: 100%;
}
.ctes-container {
  /* display: flex;
  flex-wrap: wrap; */
  /*align-items: center;*/
}
</style>